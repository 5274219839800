import axios from 'axios'
import {
    Message
} from 'element-ui'
import store from '../store';
//const baseUrl = 'http://waitujituan.hbbright.cn/api/'
const baseUrl ='https://manage.hxdmpy.com/api/'
import Cookies from "js-cookie";
var FormData = require("form-data");
const http = (method,url,params,format)=>{

    let token = "";
    if (Cookies.get("token")) {
      token = Cookies.get("token");
    }

    let headers = {
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json; charset=utf-8",
        token: token,
      };

    let config = {
        method:method,
        url:baseUrl+url,
        data:params,
        headers: headers,
    }

    // let methodUp = method.toUpperCase();
  if (format == "fd") {

    // POST请求
        config.headers["Content-Type"] = "multipart/form-data";
        if (params instanceof FormData) {
        config.data = params;
        } else {
        var formdata = new FormData();
        for (let key in params) {
            formdata.append(key, params[key]);
        }
        config.data = formdata;
        }
        config.params = {};
    }
    // return console.log(config)
    return new Promise((resolve,reject)=>{
        axios(config).then(res=>{
            switch(res.data.code){
                case 1:
                    resolve(res.data)
                break;
                case -1:
                    Message.error(res.data.msg)
                    // reject(res.data)
                break;
                case -99:
                    Message.error('登陆过期请重新登陆')
                    Cookies.remove("token");
                    Cookies.remove("name");
                    store.commit('userName','')
                    // reject(res.data)
                break;
                case -98:
                    Message.error(res.data.msg)
                    console.log('用户异常')
                break;
            }
        })
    })

}

const request = {
    get(url,params,format){
        return http('get',url,params,format)
    },
    post(url,params,format){
        return http('post',url,params,format)
    }
}


export default request
