import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    showpop1:false,
    showpop2:false,
    showpop3:false,
    site:{
      copyright:'',
      logo:''
    },
    userName:'',
    video:{},
    video2:{}
  },
  getters: {
    showpop1:state=>state.showpop1,
    showpop2:state=>state.showpop2,
    showpop3:state=>state.showpop3,
    site:state=>state.site,
    video:state=>state.video,
    video2:state=>state.video2,
    userName:state=>state.userName
  },
  mutations: {
    showpop1 (state, showpop1) {
      state.showpop1 = showpop1
    },
    showpop2 (state, showpop2) {
      state.showpop2 = showpop2
    },
    showpop3 (state, showpop3) {
      state.showpop3 = showpop3
    },
    site(state,site){
      state.site = site
    },
    video(state,video){
      state.video = video
    },
    video2(state,video2){
      state.video2 = video2
    },
    userName(state,userName){
      state.userName = userName
    }
  },
  actions: {
  },
  modules: {
  }
})

export default store
