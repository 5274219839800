<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created(){
    this.request.post('index/site').then(res=>{
        this.$store.commit('site',res.data)
    })
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
@import url("@/style/common.css");
</style>
