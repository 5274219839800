import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/index/detail.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/index/introduce.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/index/agreement.vue')
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: () => import('../views/index/newDetail.vue')
  },
  {
    path: '/newList',
    name: 'newList',
    component: () => import('../views/index/newList.vue')
  },
  {
    path: '/application',
    name: 'application',
    component: () => import('../views/application/index.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/application/list.vue')
  },
  {
    path: '/myInfo',
    name: 'myInfo',
    component: () => import('../views/application/myInfo.vue')
  },
  {
    path: '/praise',
    name: 'praise',
    component: () => import('../views/videos/praise.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/videos/video.vue')
  },
  {
    path: '/source/index',
    name: 'sourceIndex',
    component: () => import('../views/source/index.vue')
  },
  {
    path: '/source/detail',
    name: 'sourceDetail',
    component: () => import('../views/source/detail.vue')
  },
  {
    path: '/awards/index',
    name: 'awardsIndex',
    component: () => import('../views/awards/index.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/index/contact.vue')
  },
  {
    path: '/judges',
    name: 'judges',
    component: () => import('../views/index/judges.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
